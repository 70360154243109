//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PublicHeader from "../Header";
import PublicFooter from "../Footer";
import GlobalSearch from "@/components/GlobalSearch/GlobalSearch";
import ReviewsGallery from "./ReviewsGallery";
// import PluginBtn from "@/components/PluginBtn.vue";

import VideoPreview1Src from "@/assets/images/video-preview/1.jpg";
import VideoPreview2Src from "@/assets/images/video-preview/2.jpg";
import VideoPreview3Src from "@/assets/images/video-preview/3.jpg";
import VideoPreview4Src from "@/assets/images/video-preview/4.jpg";
export default {
  meta: {
    /*@V:
            titleTemplate: () => 'SalesFinder - Сервис аналитики 市场ов WB и OZON',
            meta: [
                { name: 'description', content: 'SalesFinder - 搜索 прибыльных ниш, анализ цен и продаж 竞争对手, трекинг позиций, данные по 品牌ам и продавцам. Удобная 分析 市场ов для увеличения 你的销售额!' },
            ]
            */
    //// &: 2022-06-24title: 'SalesFinder - WB 和 OZON 市场上的销售分析服务',
    titleTemplate: () => "SalesFinder - WB 和 OZON 市场上的销售分析服务",
    meta: [{
      name: "description",
      content: "SalesFinder - 搜索有利可图的利基市场, 分析 Wildberries 和 OZON 竞争对手的价格和销售额, 跟踪位置, 品牌和卖家数据. 方便的市场分析可增加您的销售额!"
    }, {
      name: "viewport",
      content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
    }]
  },
  data() {
    return {
      mounted: false,
      lottieCount: 0,
      videos: [{
        link: "https://www.youtube.com/embed/So-_nqYIRDk?si=NdvBWHdP-EfSkwaO",
        preview: VideoPreview1Src
      }, {
        link: "https://www.youtube.com/embed/GsxNkBjwE2Q",
        preview: VideoPreview2Src
      }, {
        link: "https://www.youtube.com/embed/DwZOlAZGSVs",
        preview: VideoPreview3Src
      }, {
        link: "https://www.youtube.com/embed/8xO_scdHx3E?si=DTqVS_G69vv_Xm2j",
        preview: VideoPreview4Src
      }]
    };
  },
  async mounted() {
    await this.$nextTick();
    this.mounted = true;
  },
  methods: {
    async onLottieMounted() {
      await this.$nextTick();
      this.lottieCount++;
    }
  },
  computed: {
    customPreviews() {
      const styles = `
                <style>
                    * {
                        padding:0; 
                        margin:0; 
                        overflow:hidden 
                    }
                    html,body {
                        height:100%
                    }
                    img {
                        position:absolute;
                        width:100%;
                        height:100%;
                        object-fit:cover;
                        object-position: left;
                        top:0;
                        bottom:0;
                        margin:auto
                    }
                    .btn-svg {    
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 50%;
                        margin-top: -24px;
                        height: 48px;
                    } 
                    .ytp-large-play-button-bg {
                        fill: #f00;
                        fill-opacity: 1;
                    }
                </style>
            `;
      const links = this.videos.map(item => {
        return `
                ${styles}
                <a href='${item.link}'>
                    <img src='${item.preview}' alt='Video Preview'>
                    <svg class='btn-svg' height='100%'' version='1.1' viewBox='0 0 68 48' width='100%'><path class='ytp-large-play-button-bg' d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path><path d='M 45,24 27,14 27,34' fill='#fff'></path></svg>
                </a>
                `;
      });
      return links;
    }
  },
  components: {
    PublicHeader,
    GlobalSearch,
    // PluginBtn,
    // LottieFeature1: () => import("./Lottie/Feature1"),
    // LottieFeature2: () => import("./Lottie/Feature2"),
    // LottieFeature3: () => import("./Lottie/Feature3"),
    // LottieFeature4: () => import("./Lottie/Feature4"),
    ReviewsGallery,
    PublicFooter
  }
};